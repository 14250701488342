import React, { useContext } from "react";
import "./Intro.css";
import Instagram from "../../img/instagram.png";
import Facebook from "../../img/Facebook.png";
import Linkedin from "../../img/linkedin.png";
import Demo from "../../img/dummy.jpg";
import { themeContext } from "../../Context";

const Intro = () => {
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="intro" id="Intro">
      <div className="i-left">
        <div className="i-name">
          <span style={{ color: darkMode ? "white" : "" }}>
            CONWAVE IT SOLUTIONS & SERVICES
          </span>
          <span style={{ color: darkMode ? "gray" : "" }}>
            is a dynamic IT company dedicated to providing top-notch IT
            solutions and services to businesses of all sizes. With a focus on
            innovation, quality, and customer satisfaction, we offer a
            comprehensive range of IT services designed to address the diverse
            needs of our clients. Our team of skilled professionals is committed
            to solving user problems in the most effective and efficient manner,
            ensuring seamless and secure operations for our clients.
          </span>
        </div>
      </div>
      <div className="i-right">
        <img className="i-right-image" src={Demo} alt="" />
      </div>
    </div>
  );
};

export default Intro;
