import React, { useContext } from "react";
import "./Services.css";
import HeartEmoji from "../../img/heartemoji.png";
import Glasses from "../../img/glasses.png";
import Humble from "../../img/humble.png";
import { Card } from "../Card/Card";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";

const Services = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };
  return (
    <div className="services" id="services">
      <div className="awsome">
        <span style={{ color: darkMode ? "white" : "" }}>Our Best</span>
        <span>Services</span>
        <spane>
          Software Development, IT Consulting, Cybersecurity Solutions, Cloud
          Solutions,
          <br />
          Application maintenance and support, Network Solutions Design and
          implementation of secure and efficient networks, Data Analytics
        </spane>
        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>

      {/* right */}
      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{ left: "14rem" }}
          whileInView={{ left: "20rem" }}
          transition={transition}>
          <Card
            emoji={HeartEmoji}
            heading={"Design"}
            detail={"Figma, Sketch, Photoshop, Adobe Illustrator, Adobe xd"}
          />
        </motion.div>
        {/* second card */}
        <motion.div
          initial={{ left: "11rem", top: "10rem" }}
          whileInView={{ left: "-4rem" }}
          transition={transition}>
          <Card
            emoji={Glasses}
            heading={"Developer"}
            detail={
              "Mobile App Development, Web Development, DevOps, Testing & Quality Assurance"
            }
          />
        </motion.div>
        {/* 3rd */}
        <motion.div
          initial={{ top: "19rem", left: "25rem" }}
          whileInView={{ left: "20rem" }}
          transition={transition}>
          <Card
            emoji={Humble}
            heading={"UI/UX"}
            detail={
              "UI/UX, Web Apps , Mobile Apps ,Social Media , Marketing Assets "
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        {/* 4th */}
        <motion.div
          initial={{ top: "26rem", left: "10rem" }}
          whileInView={{ top: "26rem", left: "4rem" }}
          transition={transition}>
          <Card
            emoji={Humble}
            heading={"Marketing Automation"}
            detail={
              "Web Analytics, Data Visualization, GA4 Migration, CRO, Adobe Experience Platform"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        {/* 5th */}
        <motion.div
          initial={{ top: "42rem", left: "25rem" }}
          whileInView={{ top: "42rem", left: "12rem" }}
          transition={transition}>
          <Card
            emoji={Humble}
            heading={"Salesforce"}
            detail={
              "Service Cloud , Sales Cloud , Marketing Cloud , Experience Cloud , Commerce Cloud ,Salesforce Einstien , Salesforce CPQ"
            }
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        {/* 6th */}
        <motion.div
          initial={{ top: "30rem", left: "-25rem" }}
          whileInView={{ top: "30rem", left: "-15rem" }}
          transition={transition}>
          <Card
            emoji={Humble}
            heading={"Digital Marketing"}
            detail={"Paid Ads , SEO , ORM , ABM , SMM , eCommerce"}
            color="rgba(252, 166, 31, 0.45)"
          />
        </motion.div>
        <div
          className="blur s-blur2"
          style={{ background: "var(--purple)" }}></div>
      </div>
    </div>
  );
};

export default Services;
